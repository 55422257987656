@import "../../resources/scss/variables";

@font-face {
  font-family: "Poppins Regular";
  src: url("../../resources/fonts/Poppins-Regular.ttf");
}

@font-face {
  font-family: "Roboto Regular";
  src: url("../../resources/fonts/Roboto-Regular.ttf");
}

#streamingOnLine {
  margin-top: 60px;
  margin-bottom: 60px;
  font-size: 18px;
  color: #fff !important;
  font-family: "Poppins Regular";

  & .go-back {
    background: linear-gradient(89.86deg, #fff 0.12%, #08041c 98.29%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-weight: 600;
  }

  & .title {
    font-family: "Poppins Regular";
    font-size: 36px;
    font-weight: bold;

    & span {
      background: linear-gradient(89.86deg, #fff 0.12%, #08041c 98.29%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }

  & .descripcion-salas {
    font-family: "Poppins Regular";
    font-size: 18px;
  }

  & .description {
    // font-size: 14px;
    font-family: "Poppins Regular";
    color: $color-hover;
  }

  & .likes-btn {
    margin-right: 10px;
    cursor: pointer;
    & svg {
      font-size: 32px;
    }
  }

  & .btn-likes-selected {
    color: #1426ed;
  }

  & .contentenedor-chat {
    height: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
  }

  & .name-conferencista {
    display: inline-flex;
    font-family: "Poppins Regular";
    margin-bottom: 30px;

    & .img-conferencista {
      width: 160px;
      height: 165px;
      background-repeat: no-repeat;
      background-size: cover;
      margin-right: 40px;
    }

    & .job-conferencista {
      font-weight: normal;
      font-family: "Poppins Regular";
      font-size: 18px;

      & .name {
        // background: linear-gradient(89.86deg, #fff 0.12%, #08041c 98.29%);
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
        // background-clip: text;
        // text-fill-color: transparent;
        color: #fff;
        font-size: 23px;
        font-weight: bold;
      }

      & .job {
        // background: linear-gradient(89.86deg, #fff 0.12%, #08041c 98.29%);
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
        // background-clip: text;
        // text-fill-color: transparent;
        color: #fff;
        font-size: 16px;
        font-weight: bold;
      }
    }
    
    & .description-conferencista {
      margin-top: 20px;
      & div, & p {
        color: #fff !important;
        font-family: "Poppins Regular";
        font-size: 16px;
        margin: 0;
      }
    }
  }

  & .container-sala {
    position: relative;
    border-radius: 15px;

    box-shadow: 0px 1px 10px -1px rgba(0, 0, 0, 0.61);
    font-family: "Poppins Regular";
    padding-bottom: 20px;

    &:hover {
      -webkit-box-shadow: 1px 7px 20px 8px rgba(0, 0, 0, 0.35);
      box-shadow: 1px 7px 20px 8px rgba(0, 0, 0, 0.35);
      // transform: scale(1.1);
    }

    & img {
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
    }

    & .pais {
      color: $color-normal;
      font-size: 22px;
      font-weight: bold;
      padding-top: 15px;

      padding-bottom: 20px;
    }

    & .btn-sala {
      background-color: #000;
      color: #fff;
      width: 120px;
      padding: 10px;
      text-align: center;
      margin: auto;
      cursor: pointer;

      &:hover {
        background-color: $color-normal;
        color: #000;
      }
    }
  }
}

.contenedor-encuesta {
  /* width: 100%; */
  margin: auto;
  background: #fff;
  padding: 30px 30px;
  border-radius: 10px;

  & .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
    color: $color-naranja !important;
  }

  & .div-paso {
    // background: #91b508;
    // width: 30px;
    // height: 25px;
    // padding: 10px;
    margin-top: 15px;
    font-size: 14px;
    color: #C8C8C8;
    // font-weight: bold;
    // border-radius: 50%;
    // text-align: center;
    // position: relative;
    // left: -24px;
    // top: -24px;
  }

  & .titulo-encuesta {
    color: #7C7D82;
    // background: linear-gradient(89.86deg, #fff 0.12%, #08041c 98.29%);
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
    // background-clip: text;
    // text-fill-color: transparent;
    font-size: 18px;
    text-align: center;
    margin-bottom: 20px;
    font-weight: bolder;
  }

  & .pregunta-encuesta {
    color: $color-rojo;
    font-size: 18px;
    text-align: center;
    font-weight: bold;
    width: 95%;
  }

  & .div-estrellas {
    margin: auto;
    margin-top: 20px;

    & span {
      & img {
        margin-right: 5px;
        width: 40px;
      }
    }
  }

  & .ranking {
    & .btn-si {
      background: var(--unnamed-color-91b508) 0% 0% no-repeat padding-box;
      background: #91b508 0% 0% no-repeat padding-box;
      border-radius: 68px;
      color: #fff;
      font-size: 30px;
      font-weight: bold;
      width: 100px;
      text-align: center;
      padding: 8px 0;
      float: left;
      margin-right: 20px;
    }

    & .btn-no {
      background: #e64b19 0% 0% no-repeat padding-box;
      border-radius: 68px;
      color: #fff;
      font-size: 30px;
      font-weight: bold;
      width: 100px;
      text-align: center;
      padding: 8px 0;
      float: left;
      margin-right: 20;
    }
  }

  & .btn-cerrar {
    background: #009bd9 0% 0% no-repeat padding-box;
    border-radius: 68px;
    color: #fff;
    font-size: 30px;
    font-weight: bold;
    width: 160px;
    text-align: center;
    padding: 8px 0;
    float: left;
    margin-right: 20px;
  }

  & .div-botones {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
}

@media screen and (max-width: 768px) and (orientation: portrait) {
  #streamingOnLine {
    & .contentenedor-chat {
      height: 400px;
    }

    & .name-conferencista {
      display: block;
      text-align: center;
    }
  }

  .contenedor-encuesta {
    & .titulo-encuesta {
      font-size: 15px;
    }

    & .pregunta-encuesta {
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 1180px) and (orientation: landscape) {
  #streamingOnLine {
    & .contentenedor-chat {
      height: 400px;
    }

    & .container-sala {
      & .btn-sala {
        width: 80px;
      }
    }
  }
}