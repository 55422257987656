@import "../../resources/scss/variables";

@font-face {
  font-family: "Lato Regular";
  src: url("../../resources/fonts/Lato-Regular.ttf");
}

@font-face {
  font-family: "Montserrat Regular";
  src: url("../../resources/fonts/Montserrat-Regular.ttf");
}

@font-face {
  font-family: "Montserrat Light";
  src: url("../../resources/fonts/Montserrat-Light.ttf");
}

#certificate {
  overflow: hidden;
  margin-bottom: 40px;

  & #section-questions {
    & input.MuiInputBase-input {
      font-family: "Montserrat Regular";
      color: #fff;
      font-size: 16px;
    }

    & .pregunta {
      color: #fff;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 20px;
    }

    & .MuiTypography-body1 {
      font-family: "Montserrat Regular" !important;
    }

    & .MuiRadio-colorSecondary.Mui-checked {
      // background: rgba(225, 6, 19, 1) !important;
      color: #1426ed !important;
    }

    & .MuiSwitch-colorPrimary {
      // background: #e10613 !important;
      color: #1426ed !important;
    }
  }

  & #section-certificate {
    & .subtitulos {
      color: #fff;
      font-size: 24px;
      font-weight: bold;
    }

    & .text {
      color: #fff;
      // font-family: "Montserrat Regular";
    }

    & .certificate {
      width: 100%;
      height: 785px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: top center;
      position: relative;
      transform: scale(1);

      & .name {
        position: absolute;
        top: 43%;
        left: 25%;
        color: #1426ed;
        font-size: 45px;
        font-weight: bold;
        text-transform: capitalize;
      }

      & .name-two {
        font-size: 12px;
        color: #54555b;
        // font-family: "Montserrat Regular";
        top: 90%;
        left: 35%;
        position: absolute;
        text-transform: capitalize;
      }

      & .job {
        font-size: 12px;
        color: #54555b;
        // font-family: "Montserrat Regular";
        top: 92%;
        left: 35%;
        position: absolute;
        text-transform: capitalize;
      }
    }

    & .btn-download {
      background: $color-normal;
      color: #fff;
      width: 150px;
      text-align: center;
      padding: 10px;
      border-radius: 10px;
      cursor: pointer;
      float: right;

      &:hover {
        background: $color-hover;
      }
    }

    & .contact-us {
      background: linear-gradient(89.86deg, #e10613 0.12%, #f49405 98.29%);
      color: #fff;
      padding: 60px 0;
      position: relative;

      & .title-thank-message {
        font-size: 30px;
        font-weight: bold;
        // font-family: "Montserrat Regular";
      }

      & .text-message {
        font-family: "Montserrat Light";

        & span {
          font-weight: bold;
        }
      }

      & .btn-contact-us {
        background: #fff;
        color: $color-hover;
        width: 150px;
        margin: auto;
        border-radius: 10px;
        padding: 5px;
        cursor: pointer;
      }
    }
  }

  .MuiInput-underline:before,
  .MuiInput-underline:after {
    border-bottom: 1px solid #fff !important;
  }
}

@media screen and (max-width: 425px) and (orientation: portrait) {
  #certificate {
    & #section-certificate {
      & .certificate {
        background-size: contain;
        height: 240px;

        & .name {
          top: 44% !important;
          left: 29% !important;
          font-size: 12px !important;
        }

        & .name-two {
          top: 73% !important;
          left: 34% !important;
          font-size: 8px !important;
        }

        & .job {
          top: 77% !important;
          left: 34% !important;
          font-size: 8px !important;
        }
      }

      & .btn-download {
        float: none;
      }
    }
  }
}

@media screen and (max-width: 880px) and (orientation: landscape) {
  #certificate {
    & #section-certificate {
      & .certificate {
        // background-size: contain;
        height: 440px;

        & .name {
          top: 43% !important;
          left: 25% !important;
          font-size: 22px !important;
        }

        & .name-two {
          top: 74% !important;
          left: 34% !important;
          font-size: 10px !important;
        }

        & .job {
          top: 77% !important;
          left: 34% !important;
          font-size: 10px !important;
        }
      }
    }
  }
}

@media screen and (min-width: 881px) and (max-width: 1440px) and (orientation: landscape) {
  #certificate {}
}