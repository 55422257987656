#menu {

    & .btn-menu {
        width: 100px;
    }

    & .active {
        background-color: rgb(70 70 70 / 13%) !important;
    }

    & .titulo-menu {
        color: #464646 !important;

        & svg {
            color: #2D6DB4 !important;
        }
    }

    & #estadisticas {
        color: #464646 !important;
    }
}