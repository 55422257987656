@import "../../resources/scss/variables";

@font-face {
  font-family: "Montserrat Regular";
  src: url("../../resources/fonts/Montserrat-Regular.ttf");
}

#schedule {
  overflow: hidden;
  margin-bottom: 40px;

  & h1 {
    font-size: 36px;
    color: #fff;
    // text-transform: capitalize;
    // background: linear-gradient(89.86deg, #1426ed 0.12%, #08041c 98.29%);
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
    // background-clip: text;
    // text-fill-color: transparent;
  }

  & .nombre-confernecia {
    color: #7c7d82;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 10px;
    font-family: "Montserrat Regular";
  }

  & .img-conferencista-next {
    width: 70px;
    margin-right: 10px;
  }

  & .nombre-conferencista-next {
    color: $color-rojo;
    font-weight: bold;
    font-family: "Montserrat Regular";
  }

  & .puesto-conferencista-next {
    color: #7c7d82;
    font-size: 13px;
    font-family: "Montserrat Regular";
  }

  #next-conferencia {
    font-family: "Montserrat Regular";

    & .next-event {
      color: $color-rojo;
      font-weight: bold;
      font-size: 13px;
      text-align: right;
      margin-bottom: 10px;
    }

    & .descripcion-confernecista-next {
      font-size: 14px;
    }

    & .duracion {
      color: #7c7d82;
      font-size: 13px;
    }
  }

  & .divider {
    width: 100%;
    height: 1px;
    background: #ccc;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  & .contenedor-categoria {
    & .color-categoria {
      width: 20px;
      height: 10px;
      border-radius: 50%;
    }

    & .nombre-categoria {
      margin-left: 10px;
      font-size: 14px;
    }
  }

  & .border-cat-uno {
    border-left: 5px solid #e10613;
  }

  & .border-cat-dos {
    border-left: 5px solid #D9421C;
  }

  & .border-cat-tres {
    border-left: 5px solid #1426ed;
  }

  & .border-cat-cuatro {
    border-left: 5px solid #0a0a3e;
  }

  & .border-cat-cinco {
    border-left: 5px solid #D4AF0D;
  }

  & .scroll-row {
    // overflow-x: scroll;
    // padding: 15px 0;

    & .container-day {
      margin-right: 15px;
      width: 100%;
    }
  }

  & .bg-day {
    background-color: #F8F7F7;
    padding: 15px;
  }

  & .bg-day-active {
    background-color: #fff;
  }

  & #section-schedule {
    & .btn-day {
      text-align: center;
      width: 20px;
      padding: 10px;
      font-weight: bold;
      border-radius: 50%;
      margin-bottom: 20px;
      // cursor: pointer;
      margin: auto;
      background-color: #F8F7F7;
      color: #a5a6a8;
    }

    & .img-conferencista-circulo {
      width: 45px;
    }
  }
}

@media screen and (max-width: 820px) and (orientation: portrait) {
  #schedule {
    #next-conferencia {
      display: none;
    }

    & .no-mobile {
      display: none;
    }
  }
}

@media screen and (max-width: 900px) and (orientation: landscape) {
  #schedule {
    #next-conferencia {
      display: none;
    }

    & .no-mobile {
      display: none;
    }
  }
}

@media screen and (max-width: 1180px) and (orientation: landscape) {
  #schedule {
    #next-conferencia {
      display: none;
    }

    // & .no-mobile {
    //   display: none;
    // }
  }
}