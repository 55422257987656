@import "../../resources/scss/variables";

@font-face {
  font-family: "Montserrat Regular";
  src: url("../../resources/fonts/Montserrat-Regular.ttf");
}

#login {
  height: 100vh;
  overflow: hidden;
  color: #fff;
  font-size: 18px;
  font-family: "Montserrat Regular";


  & form {
    overflow: hidden;
  }

  & .form-registro {
    width: 70%;
    margin: auto;
    overflow: hidden;
  }

  // Seccion bienvenida
  & .contenedor-logo {
    background-image: url(../../resources/images/login/fondo-derecho.jpg);
    font-family: "Montserrat Light";
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left !important;
    flex-wrap: wrap;

    & .overlap {
      & h1 {
        font-family: "Montserrat Regular" !important;
        font-size: 48px;
      }

      & p {
        padding: 0 80px;
        font-family: "Montserrat Regular";
        color: #fff;
      }
    }

  }

  // Seccion login
  & .contenedor-form-login {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left !important;
    height: 100vh;
    flex-wrap: wrap;

    & .text-form {
      font-size: 44px;
      color: #fff;
      margin-bottom: 20px;
      font-weight: bold;
    }

    & .text-reset {
      color: #fff;
      font-size: 16px;
      margin-bottom: 20px;
    }

    & .register {
      color: #fff;
      font-size: 12px;
      margin-left: 30px;

      & .register-text {
        color: #fff;
        font-weight: bold;
        cursor: pointer;
      }
    }

    & .reset-password {
      color: #fff;
      font-size: 14px;
      margin-top: 60px;
      cursor: pointer;
    }

    & .MuiButton-root {
      width: 170px;
      background: $btnAzul;
      color: #fff !important;
      padding: 5px;
      cursor: pointer;
      font-size: 14px;
      text-align: center;
      border-radius: 0px;
      text-transform: capitalize;
      font-weight: bold;
      font-family: "Montserrat Regular";

    }

    .MuiInputBase-input {
      color: #fff !important;
      font-family: "Montserrat Regular";
      font-size: 22px;
    }

    & .MuiFormLabel-root {
      color: #fff !important;
      font-family: "Montserrat Regular" !important;
    }

    & .MuiInputLabel-outlined.MuiInputLabel-shrink {
      transform: translate(0px, -18px) scale(1) !important;
      font-family: "Montserrat Regular" !important;
      color: #fff !important;
    }

    & .MuiInputBase-root {
      margin-bottom: 10px;
      border-radius: 20px;
    }

    & .MuiFilledInput-underline {

      &::before,
      &::after {
        border: none !important;
      }
    }

    & .MuiFormLabel-root.Mui-focused {
      color: #fff !important;
      font-weight: normal;
      font-size: 14px;
    }

    & .MuiTypography-body1 {
      font-family: "Montserrat Regular" !important;
    }

    & label.MuiFormControlLabel-root {
      color: #fff !important;
      font-family: "Montserrat Regular";

    }
  }

  // Seccion registro

  // Seccion reestablecer contraseña




}

@media screen and (max-width: 820px) and (orientation: portrait) {
  #login {
    overflow-y: scroll;
    overflow-x: hidden;

    & .contenedor-logo {
      height: auto;
      padding: 30px;

      & .welcome-text {
        & p {
          padding: 0;
        }
      }
    }

    & .form-registro {
      width: 100%;
    }



    & .contenedor-form-login {
      display: block;
      padding: 40px 15px;
      height: auto;

      & .MuiButton-root {
        margin: auto;
      }

      & .space-footer-form {
        display: grid;

        & .register {
          text-align: center;
          margin-top: 20px;
          margin-left: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) and (orientation: landscape) {
  #login {
    overflow-y: scroll;
    overflow-x: hidden;

    & .form-registro {
      width: 100%;
      margin: auto;
      overflow: hidden;
    }

    & .form-registro {
      width: 90%;
      margin: auto;
      overflow: hidden;
    }

    & .contenedor-logo {
      height: 100vh;

      & .welcome-text h1 {
        font-size: 35px;
      }

      & .welcome-text p {
        padding: 0;
        font-size: 18px;
      }
    }

    & .contenedor-form-login {
      // display: block;
      padding: 15px;
      height: 100vh;

      & .MuiButton-root {
        margin: auto;
      }

      & .space-footer-form {
        display: grid;

        & .register {
          text-align: center;
          margin-top: 20px;
          margin-left: 20px;
        }
      }
    }
  }
}