@import "../../../resources/scss/variables";

#homeAdmin {
background-color: #fff;
    .border-reports {
        border: 2px solid $color-hover;
        margin-bottom: 20px;

        & h2 {
            color: $color-hover
        }

        & .MuiButton-root {
            width: 170px;
            background: $color-azul;
            color: #fff !important;
            padding: 5px;
            cursor: pointer;
            font-size: 14px;
            text-align: center;
            border-radius: 0px;
            text-transform: capitalize;
            font-weight: bold;
            font-family: "Montserrat Regular";

            &:hover {
                background: transparent !important;
                border: 2px solid $color-azul !important;
                color: $color-azul !important;
            }
        }
    }
}