.input-icons i {
    position: absolute;
    color: #767676;
}

.input-icons {
    width: 90%;
    margin-bottom: 10px;
    margin-left: 0px;
    margin-right: 30px;
}

.icon {
    padding: 10px;
    min-width: 40px;
}

.input-icons .input-search {
    width: 90%;
    padding: 10px 30px;

}

@media screen and (max-width: 1024px) and (orientation: portrait) {
    .spira-logo-header {
        display: none
    }
}

@media screen and (max-width: 1366px) and (orientation: landscape) {
    .spira-logo-header {
        display: none;
    }
}