@import "../../resources/scss/variables";

#agenda-una-cita {
  & h1,
  & .titulo-section {
    font-size: 36px;
    color: #fff;
  }

  & .fecha {
    color: #e10613;
    font-weight: bold;
    margin-bottom: 10px;
  }

  & .bg-gray {
    background-color: #f8f7f7;
    padding-top: 50px;
    padding-bottom: 50px;

    & .conatiner-metworking {
      background-color: #fff;
      padding: 10px;

      & .networking {
        color: #54555b;
        font-size: 14px;
      }

      & .titulo-networking {
        color: #e10613;
        font-weight: bold;
        margin-top: 10px;
        margin-bottom: 10px;
      }

      & .descripcion-networking {
        color: #54555b;
        font-size: 14px;
      }
    }

    & .bg {
      width: 100%;
      height: 200px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
  }

  & #section-space {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  & #seccion-nuestros-productos {
    & .container-products {
      margin: auto;
      margin-right: 20px;

      & .id-product {
        color: #1426ED;
        background-color: #fff;
        padding: 15px;
        font-weight: bold;
      }

      & iframe {
        height: 245px;
      }
    }
  }

  & .bg-blue {
    margin-top: 50px;
    padding-top: 50px;
    padding-bottom: 50px;
    background: linear-gradient(270deg, rgba(20, 38, 237, 1) 34%, rgba(8, 4, 28, 1) 100%);

    // transform: matrix(-1, 0, 0, 1, 0, 0);
    & .titulo-paises-section {
      color: #fff;
      font-size: 32px;
    }

    & .bg {
      width: 100%;
      height: 200px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }

    & .info-redes {
      margin-top: 20px;
      text-align: center;

      & img {
        float: right;
        margin-left: 10px;
        cursor: pointer;
      }

      & .calendly {
        cursor: pointer;
        text-align: center;
        width: 100px;
        padding: 5px 10px;
        font-size: 14px;
        // float: left;
      }
    }
  }

  .alice-carousel__dots-item:not(.__custom):hover,
  .alice-carousel__dots-item:not(.__custom).__active {
    background: $color-azul !important;
  }
}

@media screen and (max-width: 765px) and (orientation: portrait) {
  #networking {
    & #section-our-products {
      & .container-products {
        & iframe {
          height: 185px;
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) and (orientation: landscape) {
  #networking {
    & #section-our-products {
      & .container-products {
        & iframe {
          height: 210px;
        }
      }
    }
  }
}