@import "../../../resources/scss/variables";

#conferencias-del-dia {
    & .target-conference2 {
        font-size: 12px;
        height: 200px;
        background: #fff;
        padding: 15px;
        margin-right: 15px;
        color: $color-texto;

        & img {
            width: 80px;
        }

        & .date-conference {
            font-size: 12px;
            margin-bottom: 10px;
            font-family: "Montserrat Regular";
        }

        & .name-conference {
            color: $color-texto;
            font-size: 14px;
            margin-bottom: 15px;
            font-family: "Montserrat Regular";
        }

        & .name-conferencista {
            & img {
                margin-right: 15px;
            }

            & .job-conferencista {
                color: #9198a7;
                font-weight: normal;
            }
        }

        & p {
            margin-bottom: 15px;
            font-family: "Lato Regular";
        }

        & .go-streaming {
            width: max-content;
        }
    }
}