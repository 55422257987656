@import "../../resources/scss/variables";

@font-face {
  font-family: "Lato Regular";
  src: url("../../resources/fonts/Lato-Regular.ttf");
}

@font-face {
  font-family: "Montserrat Regular";
  src: url("../../resources/fonts/Montserrat-Regular.ttf");
}

@font-face {
  font-family: "Montserrat Light";
  src: url("../../resources/fonts/Montserrat-Light.ttf");
}

#speakers {
  // font-family: "Montserrat Regular";

  & .container-speaker {
    font-family: "Montserrat Regular";
    position: relative;
    border-radius: 15px;
    height: 330px;
    // transition: 0.3s ease-in-out;
    -webkit-box-shadow: 0px 1px 10px -1px rgba(0, 0, 0, 0.61);
    box-shadow: 0px 1px 10px -1px rgba(0, 0, 0, 0.61);
    background-color: #fff;

    // &:hover {
    //   -webkit-box-shadow: 1px 7px 20px 8px rgba(0, 0, 0, 0.35);
    //   box-shadow: 1px 7px 20px 8px rgba(0, 0, 0, 0.35);
    //   transform: scale(1.1);
    // }

    & .img-conferencista {
      width: 100%;
      height: 200px;
      overflow: hidden;
      background-position: center top;
      background-size: cover;
      background-repeat: no-repeat;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;

      & img {
        transform-origin: 0 0;
        transition: transform .25s,
          visibility .25s ease-in;
      }

      &:hover img {
        transform: scale(1.12);
      }
    }

    & .container-img-logo {
      position: absolute;
      top: 143px;
      right: 3px;
      background: #fff;
      border-radius: 50%;
      padding: 10px;

      .logo {
        width: 50px;
      }
    }

    & .name {
      text-align: left;
      color: $color-rojo;
      font-size: 16px;
      font-weight: bold;
      padding-top: 15px;
      padding-left: 15px;
    }

    & .job {
      font-size: 12px;
      font-family: "Montserrat Light";
      font-weight: bold;
      color: #a5a6a8;
      text-align: left;
      padding-left: 15px;
    }

    & .social-media {
      padding-top: 10px;
      padding-left: 15px;
      padding-bottom: 15px;
      text-align: left;

      & img {
        cursor: pointer;
        margin-right: 5px;
      }
    }
  }

  & .MuiTabs-flexContainer {
    & button {}
  }

  & .MuiTab-textColorPrimary {
    color: #fff !important;
    background-color: rgba($color: #fff, $alpha: 0.3) !important;
    font-family: "Montserrat Regular";
    margin-right: 20px !important;
  }

  & .Mui-selected {
    margin-right: 20px !important;
    color: #fff !important;
    font-weight: bold !important;
    background: linear-gradient(89.86deg, #1426ed .12%, #08041c 98.29%) !important;
    border: none !important;
    font-family: "Montserrat Regular";
  }

  & .css-1aquho2-MuiTabs-indicator {
    background-color: transparent !important;
  }
}

@media screen and (max-width: 425px) and (orientation: portrait) {
  #speakers {
    & .MuiTab-textColorPrimary {
      margin-right: 5px !important;
    }

    & .Mui-selected {
      margin-right: 5px !important;
    }
  }
}

@media screen and (max-width: 767px) and (orientation: landscape) {
  #speakers {
    & .MuiTab-textColorPrimary {
      margin-right: 5px !important;
    }

    & .Mui-selected {
      margin-right: 5px !important;
    }

    & .container-speaker {
      & .img-conferencista {
        height: 300px;
      }

      & .logo {
        top: 270px;
      }
    }
  }
}