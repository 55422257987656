@import "../../resources/scss/variables";

@font-face {
    font-family: "Montserrat Regular";
    src: url("../../resources/fonts/Montserrat-Regular.ttf");
}

#espacios {
    margin-bottom: 30px;
    font-family: "Montserrat Regular";

    & h1 {
        font-size: 36px;
        color: #fff;
        // text-transform: capitalize;
        // background: linear-gradient(89.86deg, #1426ed 0.12%, #08041c 98.29%);
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
        // background-clip: text;
        // text-fill-color: transparent;
    }

    & .MuiTab-textColorPrimary {
        color: #fff !important;
        background-color: rgba($color: #fff, $alpha: 0.3) !important;
        font-family: "Montserrat Regular";
        margin-right: 20px !important;
    }

    & .Mui-selected {
        margin-right: 20px !important;
        color: #fff !important;
        font-weight: bold !important;
        background: linear-gradient(89.86deg, #1426ed .12%, #08041c 98.29%) !important;
        border: none !important;
        font-family: "Montserrat Regular";
    }

    & .css-1aquho2-MuiTabs-indicator {
        background-color: transparent !important;
    }

    .alice-carousel__dots-item:not(.__custom):hover,
    .alice-carousel__dots-item:not(.__custom).__active {
        background: $color-azul !important;
    }

    & .container-info-video {
        font-family: "Montserrat Regular";

        & .title-video {
            color: #fff;
            font-weight: bold;
            margin-bottom: 10px;
        }
    }

    & .bg {
        width: 100%;
        height: 200px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        cursor: pointer;

        & .title-tip {
            font-family: "Montserrat Regular";
            color: #fff;
            margin: auto;
            font-size: 16px;
            font-weight: bold;
            padding: 6px;
        }
    }

    & .info {
        font-family: "Montserrat Regular";
        padding: 5px;
        background: rgba($color: #fff, $alpha: 0.6);
        margin-top: -43px;

        & .titulo {
            color: #e10613;
            font-size: 13px;
            font-weight: bold;
        }

        & .descripcion {
            color: #5d5d5d;
            font-size: 12px;
        }
    }

    & .bg-toolkit {
        font-family: "Montserrat Regular";
        // height: 250px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center top;
        // background: linear-gradient(142.52deg, #efc52e 10.07%, #f49305 70.98%);
        // box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        color: #fff;
        font-size: 12px;
        // padding: 15px 0px;
        margin-bottom: -5px;
        width: 100%;

        & img {
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
                }

        & .title-tip {
            font-weight: bold;
            margin: auto;
            font-size: 16px;
            padding: 6px;
        }

        & .descripcion-toolkit {
            padding: 15px;
        }
    }

    & #speakers-today {
        & .container-speaker {
            font-family: "Montserrat Regular";
            position: relative;
            border-radius: 15px;
            height: 320px;
            // transition: 0.3s ease-in-out;
            -webkit-box-shadow: 0px 1px 10px -1px rgba(0, 0, 0, 0.61);
            box-shadow: 0px 1px 10px -1px rgba(0, 0, 0, 0.61);
            background-color: #fff;
            width: 300px;
            // &:hover {
            //     -webkit-box-shadow: 1px 7px 20px 8px rgba(0, 0, 0, 0.35);
            //     box-shadow: 1px 7px 20px 8px rgba(0, 0, 0, 0.35);
            //     transform: scale(1.1);
            // }

            & .img-conferencista {
                width: 100%;
                height: 200px;
                background-position: center top;
                background-size: cover;
                background-repeat: no-repeat;
                border-top-left-radius: 15px;
                border-top-right-radius: 15px;
            }

            & .container-img-logo {
                position: absolute;
                top: 160px;
                right: 3px;
                background: #fff;
                border-radius: 50%;
                padding: 10px;

                .logo {
                    width: 50px;
                }
            }

            & .name {
                text-align: left;
                color: $color-rojo;
                font-size: 16px;
                font-weight: bold;
                padding-top: 15px;
                padding-left: 15px;
            }

            & .job {
                font-size: 12px;
                font-family: "Montserrat Light";
                font-weight: bold;
                color: #a5a6a8;
                text-align: left;
                padding-left: 15px;
            }

            & .social-media {
                padding-top: 10px;
                padding-left: 15px;
                padding-bottom: 15px;
                text-align: left;

                & img {
                    cursor: pointer;
                    margin-right: 5px;
                }
            }
        }
    }
}

@media screen and (max-width: 425px) and (orientation: portrait) {
    #spaces {
        & .MuiTab-textColorPrimary {
            margin-right: 5px !important;
        }

        & .Mui-selected {
            margin-right: 5px !important;
        }
    }
}

@media screen and (max-width: 767px) and (orientation: landscape) {
    #spaces {
        & .MuiTab-textColorPrimary {
            margin-right: 5px !important;
        }

        & .Mui-selected {
            margin-right: 5px !important;
        }
    }
}