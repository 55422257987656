@import "../../../resources/scss/variables";

#what-see-today {
  margin-bottom: 30px;

  & .btn-video {
    width: 80px;
    padding: 10px;
    text-align: center;
    margin-top: 15px;
  }

  & .container-info-video {
    & .title-video {
      // color: #e10613;
      font-weight: bold;
      margin-bottom: 10px;
    }
  }

  & .bg {
    width: 100%;
    height: 200px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    cursor: pointer;

    & .title-tip {
      color: #fff;
      margin: auto;
      font-size: 16px;
      font-weight: bold;
      padding: 6px;
    }
  }

  & .info {
    padding: 5px;
    background: rgba($color: #fff, $alpha: 0.6);
    margin-top: -43px;

    & .titulo {
      // color: #e10613;
      font-size: 13px;
      font-weight: bold;
    }

    & .descripcion {
      color: #5d5d5d;
      font-size: 12px;
    }
  }

  & .bg-toolkit {
        font-family: "Montserrat Regular";
        // height: 250px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center top;
        // background: linear-gradient(142.52deg, #efc52e 10.07%, #f49305 70.98%);
        // box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        color: #fff;
        font-size: 12px;
        // padding: 15px 0px;
        margin-bottom: -5px;
        width: 100%;

    & .title-tip {
      font-weight: bold;
      margin: auto;
      font-size: 16px;
      padding: 6px;
    }

    & .descripcion-toolkit {
      padding: 15px;
    }
  }

  & .dia-espacio {
    background: #fff;
    color: #a5a6a8;
    border: 1px solid #ccc;
    text-align: center;
    border-radius: 5px;
    font-size: 14px;
    padding: 20px;
    margin-bottom: 20px;
  }
}