@import "../../resources/scss/variables";

@font-face {
  font-family: "Montserrat Regular";
  src: url("../../resources/fonts/Montserrat-Regular.ttf");
}

@font-face {
  font-family: "Montserrat Semi Bold";
  src: url("../../resources/fonts/Montserrat-SemiBold.ttf");
}

body {
  margin: 0;

  font-family: "Montserrat Regular";
  // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  font-size: 18px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#root::after {
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: fixed;
  z-index: -1;
  background-repeat: no-repeat;
  background-position-y: 100%;
  background-position-x: center;
  background-size: cover;
  background-position: center;
  background-color: #fff;
  background-image: url(../../resources/images/general_bg.jpg);
}

p,
h2,
h3, h1 {
  font-family: "Montserrat Regular";
}

.full {
  width: 100%;
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.bold {
  font-weight: bold !important;
}

.cursor {
  cursor: pointer;
}

.padre {
  display: table;

  & .hijos {
    display: table-cell;
    vertical-align: middle;
  }
}

.padres {
  display: table;

  & .hijoss {
    display: center;
    vertical-align: middle;
  }
}

.flex {
  display: flex;
}

.contenedor-streaming {
  width: 100%;
  text-align: center;
  // margin-bottom: 60px;

  & .estilo-streaming {
    width: 100%;
    height: 450px;
  }
}

.titulos {
  font-size: 36px;
  color: #fff;
  // background: linear-gradient(89.86deg, #1426ed 0.12%, #08041c 98.29%);
  // -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;
  // background-clip: text;
  // text-fill-color: transparent;
  font-weight: bold;
}

.blanco {
  color: #fff !important;
}

/* width scroll */
::-webkit-scrollbar {
  width: 0px;
}

.tendencias {
  color: $color-hover;
  font-weight: lighter;
}

.categoria-uno {
  color: #e10613;
  font-weight: lighter;
  font-size: 13px;
}

.bg-categoria-uno {
  background-color: #e10613;
}

.categoria-dos {
  color: #D9421C;
  font-weight: lighter;
  font-size: 13px;
}

.bg-categoria-dos {
  background-color: #D9421C;
}

.categoria-tres {
  color: #1426ed;
  font-weight: lighter;
  font-size: 13px;
}

.bg-categoria-tres {
  background-color: #1426ed;
}

.categoria-cuatro {
  color: #0a0a3e;
  font-weight: lighter;
  font-size: 13px;
}

.bg-categoria-cuatro {
  background-color: #0a0a3e;
}

.categoria-cinco {
  color: #D4AF0D;
  font-weight: lighter;
  font-size: 13px;
}

.bg-categoria-cinco {
  background-color: #D4AF0D;
}

.btn-naranja {
  color: #fff;
  background: linear-gradient(90deg, rgba(225, 6, 19, 1) 34%, rgba(243, 143, 4, 1) 100%) !important;
  font-family: "Montserrat Regular";
  text-transform: capitalize !important;
}

.btn-azul {
  color: #fff !important;
  background: linear-gradient(89.86deg, #1426ed 0.12%, #08041c 98.29%) !important;
  font-family: "Montserrat Regular" !important;
  text-transform: capitalize !important;
}

.mb30 {
  margin-bottom: 30px;
}

// celular
@media screen and (max-width: 425px) and (orientation: portrait) {
  .titulos {
    font-size: 22px;
  }

  .contenedor-streaming {
    & .estilo-streaming {
      width: 100%;
      height: auto;
    }
  }
}

@media screen and (max-width: 880px) and (orientation: landscape) {
  .titulos {
    font-size: 22px;
  }
}

// tablet
@media screen and (min-width: 881px) and (max-width: 1440px) and (orientation: landscape) {}