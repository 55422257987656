@import "../../resources/scss/variables";

@font-face {
  font-family: "Montserrat Regular";
  src: url("../../resources/fonts/Montserrat-Regular.ttf");
}

#home {
  overflow: hidden;
  margin-bottom: 40px;
  font-family: "Montserrat Regular";

  #conferencia-activa {
    & .target-conference {
      font-size: 12px;
      background: #fff;
      padding: 15px;
      color: $color-texto;

      & .date-conference {
        font-size: 12px;
        margin-bottom: 10px;
        font-family: "Montserrat Regular";
      }

      & img {
        margin-right: 5px !important;
      }

      & .name-conference {
        color: $color-azul;
        font-size: 18px;
        margin-bottom: 15px;
        font-family: "Montserrat Regular";
      }

      & .name-conferencista {
        font-size: 14px;

        & .job-conferencista {
          color: $color-azul;
          font-weight: normal;
          font-size: 13px;
        }
      }

      & p {
        color: $color-azul;

        margin-bottom: 15px;
        font-family: "Montserrat Regular";
      }
    }
  }

  & .h1-taller {
    font-size: 45px !important;
  }

  & .btn-taller {
    padding: 21px 13px !important;
    width: 300px px !important;
    font-size: 30px !important;
    height: 105px !important;
    font-weight: bolder !important;
  }

  & .section-socios {
    padding: 40px 0;
  }

  & .contenedor-streaming {
    width: 100%;
    text-align: center;
    // margin-bottom: 60px;

    & .estilo-streaming {
      width: 100%;
      height: 400px;
    }
  }

  & .logo-aliado {
    filter: brightness(0) invert(1);

    &:hover {
      filter: grayscale(0%);
    }
  }

  .alice-carousel__dots-item:not(.__custom):hover,
  .alice-carousel__dots-item:not(.__custom).__active {
    background: $color-azul !important;
  }

  & .btn-download {
    color: $color-normal !important;
    border: 2px solid $color-normal !important;
    background: transparent !important;
    margin-top: 20px;

    &:hover {
      color: $color-hover !important;
      border: 2px solid $color-hover !important;
    }
  }
}

// celular
@media screen and (max-width: 425px) and (orientation: portrait) {
  #home {
    & .h1-taller {
      font-size: 32px !important;
    }

    & .section-conferences {
      & .target-conference {
        width: 300px;
      }
    }

    & .contenedor-streaming {
      & .estilo-streaming {
        height: auto;
      }
    }
  }
}

@media screen and (max-width: 880px) and (orientation: landscape) {
  #home {
    & .section-conferences {
      & .target-conference {
        width: 350px;
      }
    }
  }
}

// tablet
@media screen and (min-width: 881px) and (max-width: 1440px) and (orientation: landscape) {
  #home {
    & .section-conferences {
      & .target-conference {
        width: 300px;
      }
    }
  }
}